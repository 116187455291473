<template>
    <div class="home">
        <div class="banner_box">
            <el-carousel trigger="click" height="450px" :interval="5000">
                <el-carousel-item v-for="item in homeInfo.banner.pc" :key="item.id">
                    <img :src="item.image" alt="" @click="tapBanner(item)" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="w">
            <div class="show_num_box">
                <div class="show_num_item">
                    <div class="bg_image">
                        <img src="../../assets/img/icon/icon_signIn_user_num.png" />
                    </div>
                    <div class="info_text_box">
                        <div class="info_name">注册用户数量</div>
                        <div class="info_num">{{ homeInfo.config.user_num }}</div>
                    </div>
                </div>
                <div class="show_num_item">
                    <div class="bg_image">
                        <img src="../../assets/img/icon/icon_ep_num.png" />
                    </div>
                    <div class="info_text_box">
                        <div class="info_name">认证企业数量</div>
                        <div class="info_num">{{ homeInfo.config.company_num }}</div>
                    </div>
                </div>
                <div class="show_num_item">
                    <div class="bg_image">
                        <img src="../../assets/img/icon/icon_bids_num.png" />
                    </div>
                    <div class="info_text_box">
                        <div class="info_name">招投标数量</div>
                        <div class="info_num">{{ homeInfo.config.invitation_num }}</div>
                    </div>
                </div>
                <div class="show_num_item">
                    <div class="bg_image">
                        <img src="../../assets/img/icon/icon_city_num.png" />
                    </div>
                    <div class="info_text_box">
                        <div class="info_name">覆盖城市数量</div>
                        <div class="info_num">{{ homeInfo.config.city_num }}</div>
                    </div>
                </div>
            </div>

            <div class="home_area_box">
                <div class="home_area_title">
                    <img src="../../assets/img/home/bg_home_getbids.png" alt="" />
                </div>
                <div class="home_area_list_box">
                    <BidsList bidsType="invitation" :lists="list_data.lists" :numberRow="3"></BidsList>
                </div>
                <div class="look_more">
                    <button class="s_blue_btn" @click="goLink('/callBids')">查看更多</button>
                </div>
            </div>
            <div class="home_area_box">
                <div class="home_area_title">
                    <img src="../../assets/img/home/bg_home_ep_title.png" alt="" />
                </div>
                <div class="home_area_ep_list_box">
                    <div class="ep_bg_box">
                        <img src="../../assets/img/home/bg_home_vip_tui.png" alt="" @click="goLink('/personalCenter/member')" />
                    </div>
                    <div class="ep_list_box">
                        <epList itemType="home" :lists="companyObj.lists" :numberRow="3"></epList>
                    </div>
                </div>
                <!-- <div class="look_more">
          <button class="s_blue_btn">查看更多</button>
        </div> -->
            </div>
            <div class="home_area_box">
                <div class="home_area_title">
                    <img src="../../assets/img/home/bg_home_purchase.png" alt="" />
                </div>
                <div class="home_area_list_box">
                    <purchaseList itemType="home" :lists="purchaseObj.lists" :numberRow="3"></purchaseList>
                </div>
                <div class="look_more">
                    <button class="s_blue_btn" @click="goLink('/recruitInfo')">查看更多</button>
                </div>
            </div>
            <!-- 新闻区域 -->
            <div class="home_area_box">
                <div class="news_box_title_box">
                    <Tabs :fnSz="30" :gapSize="64" :showBorder="true" :tabData="newsBars" :paddingBottom="22" />
                    <div class="small_check_more">
                        <div class="check_text" @click="goLink('/home-news-list')">查看更多</div>
                        <div class="check_icon">
                            <img src="../../assets/img/icon/icon_blue_right_arr.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="news_list_box" v-if="newsObj.lists.length">
                    <div class="left_one_news_box" @click="goDetails(newsObj.lists[0].id)">
                        <div class="news_item_box">
                            <div class="news_time_box">
                                <div class="news_time_month">
                                    {{ newsObj.lists[0].month_text }}
                                </div>
                                <div class="news_time_day">
                                    {{ newsObj.lists[0].day_text }}
                                </div>
                            </div>
                            <div class="news_content_box">
                                <div class="news_title_box">
                                    {{ newsObj.lists[0].title }}
                                </div>
                                <div class="news_desc_box">
                                    {{ newsObj.lists[0].desc }}
                                </div>
                            </div>
                        </div>
                        <div class="news_img_box">
                            <img :src="newsObj.lists[0].image" alt="" />
                        </div>
                    </div>
                    <div class="right_news_list_box">
                        <div
                            class="news_item_box"
                            v-for="(item, index) in newsObj.lists.slice(1, 4)"
                            :key="index"
                            @click="goDetails(item.id)"
                        >
                            <div class="news_time_box">
                                <div class="news_time_month">
                                    {{ item.month_text }}
                                </div>
                                <div class="news_time_day">
                                    {{ item.day_text }}
                                </div>
                            </div>
                            <div class="news_content_box">
                                <div class="news_title_box">
                                    {{ item.title }}
                                </div>
                                <div class="news_desc_box">
                                    {{ item.desc }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BidsList from '../../components/Bids/BidsList.vue';
    import purchaseList from '../../components/purchase/purchaseList.vue';
    import epList from '../../components/epCom/epList.vue';
    import mixin from '../../mixin';
    import Tabs from '../../components/Tabs.vue';

    export default {
        mixins: [mixin],
        components: {
            BidsList,
            purchaseList,
            epList,
            Tabs
        },
        data() {
            return {
                homeInfo: {
                    banner: {},
                    config: {},
                    icon: []
                },
                page: {
                    page: 1,
                    page_size: 9
                },
                purchaseObj: {
                    lists: [],
                    total: 0
                },
                companyObj: {
                    lists: [],
                    total: 0
                },
                jobObj: {
                    lists: [],
                    total: 0
                },
                resumeObj: {
                    lists: [],
                    total: 0
                },
                newsObj: {
                    lists: [],
                    total: 0
                },
                newsBars: [
                    {
                        text: '新闻资讯',
                        value: 'getNewsList'
                    }
                ]
            };
        },
        created() {
            this.getHomeInfo();
            this.getData();
        },
        methods: {
            tapBanner(curBanner) {
                console.log(curBanner);
                switch (curBanner.type) {
                    case 0:
                        break;
                    case 1:
                        this.$router.push({
                            path: '/ep-details',
                            query: {
                                id: curBanner.url
                            }
                        });
                        break;
                    case 2:
                        this.$router.push({
                            path: '/personalCenter/member'
                        });
                        break;
                    case 3:
                        this.$router.push({
                            path: '/callBids/details',
                            query: {
                                id: curBanner.url
                            }
                        });
                        break;
                }
            },
            getHomeInfo() {
                this.$api.getHomeInfo().then((res) => {
                    console.log(res, '当前查询信息');
                    localStorage.setItem('service_tel', res.data.config.service_tel);
                    this.homeInfo = res.data;
                });
            },
            getData() {
                this.getList('getInvitationList', this.page);
                this.$api.getPurchaseList({ ...this.page }).then((res) => {
                    if (res.code == 1) {
                        this.purchaseObj.lists = res.data.lists;
                        this.purchaseObj.total = res.data.total_num;
                    }
                });
                this.$api.getHomeCompany({ ...this.page }).then((res) => {
                    if (res.code == 1) {
                        this.companyObj.lists = res.data.lists;
                        this.companyObj.total = res.data.total_num;
                    }
                });
                this.$api.getResumeList({ ...this.page }).then((res) => {
                    if (res.code == 1) {
                        this.resumeObj.lists = res.data.lists;
                        this.resumeObj.total = res.data.total_num;
                    }
                });
                this.$api.getJobList({ ...this.page }).then((res) => {
                    if (res.code == 1) {
                        this.jobObj.lists = res.data.lists;
                        this.jobObj.total = res.data.total_num;
                    }
                });
                this.$api.getNewsList({ page: 1, page_size: 4, is_home: 1 }).then((res) => {
                    if (res.code == 1) {
                        this.newsObj.lists = res.data.lists.map((item) => {
                            let time = item.create_time.split(' ');

                            let timeArr = time[0].split('-');
                            item.month_text = `${timeArr[0]}-${timeArr[1]}`;
                            item.day_text = `${timeArr[2]}`;
                            return item;
                        });
                        console.log(this.newsObj.lists, 'aaaaaaaaaaa');
                        this.newsObj.total = res.data.total_num;
                    }
                });
            },
            goDetails(id) {
                this.$router.push({
                    path: '/home-news-details',
                    query: {
                        id
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .w {
        width: 1200px;
        margin: 0 auto;
    }
    .banner_box {
        margin-bottom: 39px;
        img {
            cursor: pointer;
        }
    }
    .show_num_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 42px;

        .show_num_item {
            width: 288px;
            height: 132px;
            position: relative;
            flex: none;
            .bg_image {
                position: absolute;
                top: 0;
                left: 0;
                width: 288px;
                height: 132px;
                z-index: 1;
                image {
                    width: 100%;
                    height: 100%;
                }
            }
            .info_text_box {
                position: absolute;
                top: 14px;
                left: 14px;
                z-index: 2;
                color: #ffffff;
                .info_name {
                    font-size: 18px;
                    color: rgba(255, 255, 255, 0.8);
                    margin-bottom: 4px;
                }
                .info_num {
                    font-size: 24px;
                }
            }
        }
    }
    .home_area_box {
        margin-bottom: 55px;
        .home_area_title {
            height: 72px;
            margin-bottom: 20px;
        }
        .news_box_title_box {
            position: relative;
            .small_check_more {
                position: absolute;
                bottom: 14px;
                right: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                .check_text {
                    color: #999999;
                    font-size: 16px;
                    &:hover {
                        color: #3f63ff;
                    }
                }
                .check_icon {
                    width: 14px;
                    height: 14px;
                    margin-left: 2px;
                }
            }
        }
        .news_list_box {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;

            .left_one_news_box {
                width: 593px;
                height: 490px;
                background: #ffffff;
                box-shadow: 0px 0px 5px 0px #e4e9ff;
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                box-sizing: border-box;

                .news_item_box {
                    box-shadow: none;
                    border: none;

                    &:hover {
                        transform: translateY(0);
                        box-shadow: 0px 0px 0px 0px #ffffff;
                    }
                    .news_content_box {
                        box-sizing: border-box;
                        .news_desc_box {
                            font-size: 16px;
                            font-weight: 400;
                            color: #666666;
                            line-height: 22px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .news_img_box {
                    height: 290px;
                    border-radius: 0px 0px 0px 0px;
                    opacity: 1;
                    box-sizing: border-box;
                    padding: 0 26px 0 31px;
                    img {
                        cursor: pointer;
                    }
                }
            }

            .right_news_list_box {
            }
            .news_item_box {
                width: 593px;
                height: 156px;
                background: #ffffff;
                box-shadow: 0px 0px 5px 0px #e4e9ff;
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                display: flex;
                box-sizing: border-box;
                padding: 27px 0 23px;
                transition: transform 0.3s ease;
                margin-bottom: 11px;
                cursor: pointer;
                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0px 0px 12px 0px #c5c8d5;
                }
                .news_time_box {
                    width: 118px;
                    flex: none;
                    text-align: center;
                    border-right: 1px dashed #9fa1a5;
                    padding-top: 9px;
                    .news_time_month {
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        margin-bottom: 22px;
                    }
                    .news_time_day {
                        font-size: 36px;
                        font-weight: 700;
                        color: #9e9e9e;
                    }
                }
                .news_content_box {
                    padding: 9px 24px 10px 19px;
                    width: 444px;
                    .news_title_box {
                        font-size: 18px;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom: 6px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .news_desc_box {
                        font-size: 16px;
                        font-weight: 400;
                        color: #666666;
                        line-height: 22px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        .home_area_ep_list_box {
            display: flex;
            .ep_bg_box {
                width: 220px;
                height: 428px;
                border-radius: 8px;
                overflow: hidden;
                margin-right: 18px;
                flex: none;
                cursor: pointer;
            }
            .ep_list_box {
                flex: 1;
            }
        }
        .home_area_list_box {
            margin-bottom: 32px;
        }
        .look_more {
            display: flex;
            justify-content: center;
        }
    }
</style>